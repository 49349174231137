@import "./mixin";
@import "../assets/font/common-font";
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  // --primary: #00b0f0;
  --primary: #4fadfe;
  --primary-bg: #57B6B2;
  --primaryblue: #00A0E3;
  --primary-yellow:#FFFF00;
  // --ciyan-bg: #57B6B2;
  --primaryRed: #ED1C24;
  --footerBG: #445259;
  --primary-alt: #51a6f0;
  --light-blue: #2082ca;
  --dark-blue: #29256f;
  --secondary: #5e9bcc;
  --secondary-hover: #5e9bcc;
  --black: #000;
  --white: #fff;
  --unread: #d6ebff;
  --gray: #e0dfdc;
  --white-smoke: #f5f5f5;
  --dark-gray: #525252;
  --success: #1a8d08;
  --green: #73cb57;
  --purple: #7e57c2;
  --scroll-border: #e3e3e3;
  --light-gray: #f2f2f2;
  --default-box-shadow: 0 2px 15px 0 #dcdcdc;
  --gradient-background: linear-gradient(to right, #f3b605 0%, #ff6b5e 100%);
  --padding-desktop: 60px 0;
  --padding-desktop-large: 90px 0;
  --border-color: #dadce0;
  --alice-blue: #f5fcff;
  --very-light-gray: #f9f9f9;
  --light-shade-gray: #e7e9ed;
  --alice-light-blue: #f4f7f9;
  --caribbean-green: #026ec1;
  --dark-charcoal: #333;
  --chinese-silver: #ccc;
}

@mixin card-bg {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: var(--white-smoke);
  background: hsla(0deg, 0%, 100%, 0.98);
  border: 1px solid #efefef;
  -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01%), 0 -2px 16px 0 rgba(0, 0, 0, 0.05%);
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0%), 0 -2px 16px 0 rgba(0, 0, 0, 0.05%);
}
@font-face {
  font-family: 'bebas_neueregular';
  src: url('./fonts/bebasneue-regular-webfont.woff2') format('woff2'),
       url('./fonts/bebasneue-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

body::-webkit-scrollbar-track,
.main-content-container::-webkit-scrollbar-track,
.ant-table-content::-webkit-scrollbar-track,
.ant-modal-wrap::-webkit-scrollbar-track,
.left-section::-webkit-scrollbar-track {
  border-radius: 10px;
}

body::-webkit-scrollbar,
.main-content-container::-webkit-scrollbar,
.ant-table-content::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar,
.left-section::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

body::-webkit-scrollbar-thumb,
.main-content-container::-webkit-scrollbar-thumb,
.ant-table-content::-webkit-scrollbar-thumb,
.ant-modal-wrap::-webkit-scrollbar-thumb,
.left-section::-webkit-scrollbar-thumb {
  background-color: var(--scroll-border);
  border-radius: 10px;
}

// webkit scrollbar ends

body {
  font-family: Poppins, Roboto, Rubik, sans-serif;
  font-size: 12px;
  overflow-x: hidden;
  background: var(--primary-bg);
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins";
  font-weight: 600;
}

a {
  color: var(--primary);
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

hr {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #b6b6b6;
  box-sizing: content-box;
  height: 0;
  margin: 16px 0;
  overflow: visible;
}
.pt-12 {
  padding-top: 12vh;
}
.relative {
  position: relative;
}
.border-top {
  border-top: 1px solid var(--chinese-silver);
}

.box-shadow {
  box-shadow: var(--default-box-shadow);
}

.ant-layout-header {
  -webkit-box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  -moz-box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  z-index: 9;
}

h2 {
  font-size: 21px;
}

a.standard,
button,
button.primary,
.ant-btn-default,
.ant-btn-primary,
.ant-btn-standard,
.ant-btn-secondary,
.ant-btn-button.secondary {
  border-radius: 3px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  outline: none;
  text-shadow: none;
  width: auto;
}

a.standard,
.ant-btn-primary,
.ant-btn-secondary {
  // box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  color: var(--white);
  font-weight: 500;
}

a.standard,
.ant-btn-primary,
.btn-glow.primary {
  background: var(--primary);
  border: transparent;
  color: var(--white);
}

.ant-btn-primary:hover,
.btn-glow.primary:hover,
.ant-btn-primary:focus {
  background: var(--primary-alt);
  border-color: var(--primary-alt);
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.btn-primary {
  background: var(--secondary);
  border: 1px solid var(--secondary);
  color: var(--white);
}

.ant-btn-button.secondary {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: var(--white);
}

.ant-btn-default {
  border: 1px solid  var(--primary) !important;
  color: var(--primary);
}

.btn-white {
  background: var(--white);
  border: 1px solid var(--white);
  color: var(--primary);
}

.btn-coloured {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: var(--white);
  display: inline-block;
  text-align: center;
}

.btn-coloured:hover {
  background-color: var(--secondary-hover);
  color: var(--white);
}

.btn-large {
  border-radius: 3px;
  font-size: 16px;
  padding: 12px 31px;

  @media (max-width: 360px) {
    width: 100%;
  }
}

.btn-right-space {
  margin-right: 20px;

  @media (max-width: 600px) {
    margin-right: 0;
  }
}

.btn-border-white {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);
}

// input fields starts

.ant-input:focus,
.ant-input-focused,
.ant-picker-focused,
.ant-picker-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @include antInputFocus;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
textarea,
.ant-picker,
select,
.ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 3px;
  font-size: 14px;
  height: 35px;
  outline: none;

  // padding: 6px 8px;
  position: relative;
  width: 100%;
}

.ant-input {
  border: none !important;
  border-radius: 3px;
}

input[type="text"],
input[type="text"]:hover,
input[type="text"]:focus {
  outline: none;
}

.ant-input-affix-wrapper {
  border: none;
  border-radius: 3px;
  box-shadow: none;
  height: 35px;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .ant-input-suffix {
    border-bottom: 1px solid #d9d9d9 !important;
    margin-left: 0;
  }
}

.ant-select-multiple .ant-select-selector {
  height: auto !important;
}

.ant-select-selection-item {
  color: #262626;
}

.ant-select-selector {
  border-radius: 3px !important;
  height: 35px !important;
}

.ant-select.select-after.ant-select-single.ant-select-show-search {
  min-width: 40px !important;
}

.ant-input-affix-wrapper input[type="text"],
.ant-input-affix-wrapper input[type="password"] {
  height: auto;
}

.ant-checkbox-wrapper {
  font-size: 14px;
}

// input fields ends here

.ant-layout {
  background: var(--white);
}

// header changes

.ant-layout-header {
  background: var(--white);
  border-radius: 0;
  color: var(--dark-charcoal);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#2c3742", endColorstr="#28303a", GradientType=0);
  height: 54px;
  line-height: 52px;
  margin-bottom: 0;
  min-height: 54px;
  padding: 0 12px 0 0 !important;
}

.ant-tabs-tab,
.ant-tabs {
  font-size: 14px;
}

.ant-layout-footer {
  background: var(--black);
  color: var(--white);
  padding: 16px 50px;
  z-index: 1;

  p {
    @media (max-width: 900px) {
      margin-bottom: 10px;
    }
  }

  p,
  ul li a {
    color: var(--white);
  }

  @media (max-width: 900px) {
    margin-top: 30px;
    padding: 30px 20px;
    text-align: center;
  }
}

.ant-select-multiple .ant-select-selector {
  height: auto !important;
}

// button starts

.ant-table-cell .btn-group > .btn.glow.dropdown-toggle {
  height: 28px;
}

// loading content starts

.loading-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
}

.loading-content img {
  height: auto;
  margin-bottom: 20px;
  width: 100px;
}

.loading-content h1 {
  font-size: 22px;
  font-weight: 600;
}

.align-right {
  text-align: right;
}

.ant-collapse > .ant-collapse-item {
  background: var(--white) !important;
  border-bottom: 1px solid #c6d2d9;
}

// form fields

.form-fields,
.ant-modal-content .form-field {
  margin-bottom: 13px;
}

.form-fields label,
.ant-modal-content .form-field label {
  font-weight: bold;
}

.ant-radio-group label {
  font-weight: normal;
}

.ant-modal-content .form-field label {
  font-size: 12px;
  margin-bottom: 3px;
}

.ant-tabs-nav .ant-tabs-tab-btn {
  font-size: 15px;
}

.ant-tabs-nav .ant-tabs-ink-bar {
  background: var(--secondary);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary);
}

.top-title-section {
  margin-bottom: 7px;
  margin-top: 23px;
  width: 100%;

  h2 {
    font-size: 19px;
    font-weight: 600;
  }
}

// ant steps

.ant-steps-navigation .ant-steps-item::before,
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--secondary);
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--secondary);
}

// sider
#mobile-sider-menu {
  @media (max-width: 900px) {
    display: none;
    position: absolute;
    z-index: 2;
  }
}

.required {
  color: #f44336;
  margin-left: 4px;
}

.ant-input[disabled],
.ant-picker-input > input[disabled] {
  color: #262626;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-upload {
  width: 100% !important;
}

.fixed-action-area {
  text-align: center;

  button {
    margin: 0 5px;
  }
}

// upload field starts

.file-upload-field {
  background: #fbfbfb;
  border: 1px dashed #b3b3b3;
  border-radius: 5px;
  color: var(--black);
  cursor: pointer;
  padding: 20px;
  text-align: center;

  i {
    color: var(--secondary);
  }
}

.list-field {
  border-radius: 10px;
  border-top: 1px solid #ededed;
  box-shadow: 5px 5px 20px #e8e8e8;
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 15px;
  padding-bottom: 8px;
  position: relative;

  >.ant-row {
    flex: 1;
  }

  .remove-column {
    .btn-glow.delete-field {
      padding: 0 4px;
    }
  }
}

.custom-loader-back-active {
  height: 100vh !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2147483647;
}

.custom-loader {
  top: 53px !important;
}

.table.borderless {
  td,
  th {
    border: 0;
  }
}

.ant-btn-success {
  background: #96bf48;
  border: 1px solid #99bd56;
  color: var(--white);

  &:active,
  &:hover,
  &:focus {
    background: darken($color: #99bd56, $amount: 8);
    border-color: darken($color: #99bd86, $amount: 8);
    color: var(--white);
  }
}

// ant-popover-inner-content action buttons

.ant-popover-inner-content .action-buttons {
  min-width: 100px;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: block;

      a {
        border-bottom: 1px solid var(--light-gray);
        color: #444;
        display: block;
        padding: 5px 0;
        text-align: left;

        i {
          margin-right: 6px;
          position: relative;
          top: 2px;
        }
      }
    }

    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

.ant-checkbox-wrapper.middle .ant-checkbox {
  top: 0;
}

.field-array {
  display: flex;

  >.ant-space-item {
    flex: 1;

    .ant-skeleton-element {
      display: block;
      flex: 1;

      .ant-skeleton-input {
        flex: 1;
      }
    }
  }
}

ul {
  &.filter-buttons {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      a {
        color: var(--dark-charcoal);
        padding: 5px 10px;

        i {
          color: var(--caribbean-green);
          margin-right: 8px;
        }
      }
    }
  }
}

.action-content {
  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li {
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      display: block;
      padding: 7px 0;

      a {
        border-bottom: 1px solid var(--light-gray);
        color: #444;
        display: block;
        padding: 5px 0;
        text-align: left;

        i {
          margin-right: 6px;
          position: relative;
          top: 2px;
        }
      }
    }

    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

// new styles

.cursor-pointer {
  cursor: pointer;
}

.paragraph-body {
  font-size: 1.125rem;
  line-height: 32px;
  margin: 0;
  word-break: break-word;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 27px;
  }
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;

  // max-width: 1350px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  @media (max-width: 1440px) {
    max-width: 100vw;
  }
}

.bg-light-gray {
  background-color: #ecf0f5;
}

.color-primary {
  color: var(--primary);
}

.color-red {
  color: red;
}
.margin-top {
  margin-top: 10px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.margin-right {
  margin-right: 10px;
}
.margin-left {
  margin-left: 10px;
}
.margin-bottom30 {
margin-bottom: 30px;
}
.fp-watermark {
  display: none;
}